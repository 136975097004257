/* div.fr-wrapper > div > a {
  font-size: 0px !important;
  padding: 0px !important;
  height: 0px !important;
} */

.elle_quote {
  position: relative;
  border-radius: 20px;
	box-shadow: 0px 3px 24px rgba(0,0,0,16%);
	background-color: #FFFFFF;
	padding: 20px;
}

.elle_quote p.blockquote {
  font-family: 'kalatexa-regular';
  font-size: 20px;
  margin: 0;
}

.elle_quote .elle_quote-share {
  position: relative;
  display: flex;

  margin: 0;
  padding: 0;
  word-break: inherit;
}

.elle_quote .elle_quote-share > span {
  color: #a0a0a0;
  font-size: 16px;
  font-family: 'kalatexa-regular';
}

.elle_quote .elle_quote-share ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding-left: 0;
}
